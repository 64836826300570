.loading-animation {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center;     /* Centers vertically */
  width: 100%;
  height: 100%;            /* Ensures it fills the parent container */
}

.loading-animation svg {
  max-width: 150px; /* Adjust as needed */
  width: 100%;
  height: auto;
}
