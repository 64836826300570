/* Import Montserrat weights */
@import '@fontsource/montserrat/100.css'; /* Thin */
@import '@fontsource/montserrat/200.css'; /* Extra Light */
@import '@fontsource/montserrat/300.css'; /* Light */
@import '@fontsource/montserrat/400.css'; /* Regular */
@import '@fontsource/montserrat/500.css'; /* Medium */
@import '@fontsource/montserrat/600.css'; /* Semi-Bold */
@import '@fontsource/montserrat/700.css'; /* Bold */
@import '@fontsource/montserrat/800.css'; /* Extra-Bold */
@import '@fontsource/montserrat/900.css'; /* Black */

/* Import Inter weights */
@import '@fontsource/inter/100.css'; /* Thin */
@import '@fontsource/inter/200.css'; /* Extra Light */
@import '@fontsource/inter/300.css'; /* Light */
@import '@fontsource/inter/400.css'; /* Regular */
@import '@fontsource/inter/500.css'; /* Medium */
@import '@fontsource/inter/600.css'; /* Semi-Bold */
@import '@fontsource/inter/700.css'; /* Bold */
@import '@fontsource/inter/800.css'; /* Extra-Bold */
@import '@fontsource/inter/900.css'; /* Black */

/* TODO: Play with transitions a bit - may need to revert */
body {
  margin: 0;
  font-family:  "Inter", 'Montserrat', 'Helvetica', 'Arial', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 0.3s ease, color 0.3s ease;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
