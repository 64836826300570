.radial-menu {
  position: relative;
  width: 200px;   /* same as the SVG's width */
  height: 200px;
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
  pointer-events: none;
}

.radial-menu.expanded {
  transform: scale(1);
  pointer-events: auto;
}

.center-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  background-color: #f44336;
  color: #fff;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: 2; /* above the arcs */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
}

/* Our donut arcs are in this <svg> behind the center circle */
.donut {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
